<template>
    <section class="section section-shaped section-lg my-0">
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <h4 class="text-black">Termos de Uso e Política de Privacidade</h4>
                    <p class="lead">
                        Ao acessar ou utilizar quaisquer dos serviços da Cevik Soluções em TI EIRELI,
                        doravante denominada simplesmente “iNovaQuant”, com sede na cidade de São Paulo, Estado
                        de São Paulo, inscrita no CNPJ/MF sob o nº 16.580.985/0001-01 (“Cevik”), por meio de programa
                        disponibilizado pela iNovaQuant (“Software”) nos endereços eletrônicos
                        https://www.inovaquant.com (“Site”) e https://www.inovaquant.com/app (“web app”), utilizando-se de
                        qualquer meio de acesso, incluindo aparelhos móveis, celulares, tablets, computadores
                        pessoais, navegadores de internet e demais meios de acesso que vierem a ser desenvolvidos,
                        você (“Usuário”), na qualidade de pessoa física ou jurídica, leu, entendeu, concorda e aceita (e
                        tem a capacidade jurídica para tanto) as presentes cláusulas e condições, vinculando-se e
                        obrigando-se integralmente ao presente termo de uso (“Termo de Uso e de Consentimento”).
                        O objetivo social é a prestação de serviços e soluções financeiras por meio de Softwares no modelo SaaS.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {};
</script>
<style>
</style>