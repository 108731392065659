import { User, Token} from '../proto/qis/account/v1/account_pb';
import { AccountServicePromiseClient, UserAccountRequest, UserAccountResponse } from '../proto/qis/account/v1/account_api_grpc_web_pb';
import { AuthorizationInterceptor } from './interceptors/authorization_interceptor';

export default class {
    
    constructor (private accountService:AccountServicePromiseClient = new AccountServicePromiseClient(process.env.VUE_APP_GRPC_URL, null, {'unaryInterceptors': [new AuthorizationInterceptor()]})) {
    }

    async createUser(data:any) {
        const user = new User();
        const request = new UserAccountRequest();
        user.setName(data.name);
        user.setEmail(data.email)
        user.setPassword(data.password)
        request.setUser(user);
        try {
            return await this.accountService.createUser(request, {});
        } catch (err) {
            throw err
        }
    }

    async doLogin(data:any) {
        const user = new User();
        const request = new UserAccountRequest();
        user.setEmail(data.email)
        user.setPassword(data.password)
        request.setUser(user);
        try {
            return await this.accountService.login(request, {});
        } catch (err) {
            throw err
        }
    }

    async GoogleLogin(data:any) {
        const user = new User();
        const request = new UserAccountRequest();
        const token = new Token();
        token.setSignedToken(data);
        user.setToken(token);
        request.setUser(user);
        try {
            return await this.accountService.googleLogin(request, {});
        } catch (err) {
            throw err
        }
    }
}