
import Account from '../services/account'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import { required, minLength, between, sameAs } from 'vuelidate/lib/validators'
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'

export default {
    components: {
      SyncLoader
    },
    data() {
        return {
           errorMessages: [],
           user: {
               name: '',
               email: '',
               password: '',
               cellphone: '',
               privacy_policy: false,
               submitted: false,
               button: false,
           },
            clientId: "770213431496-6n2r2s6038emppjo40u32h1lviq2hb7f.apps.googleusercontent.com"
        }
    },
    validations: {
        user: {
            name: {
                required,
            },
            email: { 
                required,
            },
            password: {
                required
            },
            cellphone: {
            },
            privacy_policy: {
                accepted: sameAs( () => true ) 
            }
        },
    },
    directives: {
        GoogleSignInButton
    },
    methods: {
        doRegister(e) {
            this.user.submitted = true;
            this.user.button = true;
            this.errorMessages = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.user.button = false;
                return;
            }
            new Account().createUser(this.user).then((result) => {
                this.$swal.fire(
                    'Cadastro realizado com sucesso',
                    'Por favor, verifique as instruções enviada para sua caixa de e-mail para validar seu acesso.',
                    'success'
                )
            })
            .catch(error => {
                this.errorMessages.push('Dados inválidos, por favor tente novamente mais tarde.');
            })
            .finally(() => {
                this.user.button = false;
            });
        },
        redict_app() {
            this.$router.push({ name: 'profile' });
            this.$root.bus.$emit('update_login', true);
            // var root_url = window.location.href;
            // var url = root_url.replace('login', 'profile');
            // window.location.href = url;
        },
        OnGoogleAuthSuccess (idToken) {
            this.errorMessages = [];
            this.user.button = true;
            new Account().GoogleLogin(idToken)
            .then((result) => {
                localStorage.setItem('user_token', result.getAccount().getToken());
                this.redict_app();
            })
            .catch(error => {
                this.errorMessages.push('E-mail e/ou senha inválidos. Verifique se seu e-mail e senha estão corretos e tente novamente.');
            }).finally(() => {
                this.user.button = false;
            });
        },
        OnGoogleAuthFail (error) {
            this.errorMessages = [];
            this.errorMessages.push('E-mail e/ou senha inválidos. Verifique se seu e-mail e senha estão corretos e tente novamente.');
        }
    }
};
