
import Account from '../services/account';
import { required, minLength, between } from 'vuelidate/lib/validators';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';

export default {
    components: {
      SyncLoader
    },
    data() {
        return {
           errorMessages: [],
           user: {
               email: '',
               password: '',
               submitted: false,
               button: false,
           },
        }
    },
    validations: {
        user: {
            email: { 
                required,
            },
            password: {
                required
            }
        },
    },
    directives: {
    },
    mounted() {
    },
    methods: {
        doLogin(e) {
            this.user.submitted = true;
            this.user.button = true;
            this.errorMessages = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.user.button = false;
                return;
            }
            new Account().doLogin(this.user)
            .then((result) => {
                localStorage.setItem('user_token', result.getUser().getToken().getSignedToken());
                this.redict_app();
            })
            .catch(error => {
                this.errorMessages.push('E-mail e/ou senha inválidos. Verifique se seu e-mail e senha estão corretos e tente novamente.');
            }).finally(() => {
                this.user.button = false;
            });
        },
        redict_app() {
            this.$router.push({ name: 'profile' });
            this.$root.bus.$emit('update_login', true);
            // var root_url = window.location.href;
            // var url = root_url.replace('login', 'profile');
            // window.location.href = url;
        },
        handleClickLogin(){
            this.errorMessages = [];
            this.user.button = true;
            let client = window.google.accounts.oauth2.initTokenClient({
            client_id: '770213431496-f2e0rr3kq6emic9ate59g3lhm25meqmi.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/userinfo.profile \
                    https://www.googleapis.com/auth/userinfo.email \
                    openid',
            ux_mode: 'popup',
            include_granted_scopes: true,
            prompt: "consent",
                callback: (response) => {
                    if(response.access_token){
                        new Account().GoogleLogin(response.access_token)
                        .then((result) => {
                            localStorage.setItem('user_token', result.getUser().getToken().getSignedToken());
                            this.redict_app();
                        })
                        .catch(error => {
                            this.errorMessages.push('E-mail e/ou senha inválidos. Verifique se seu e-mail e senha estão corretos e tente novamente.');
                        }).finally(() => {
                            this.user.button = false;
                        });
                    }
                },
            });
            client.requestAccessToken();
        },
        OnGoogleAuthSuccess(idToken) {
            this.errorMessages = [];
            this.user.button = true;
            new Account().GoogleLogin(idToken)
            .then((result) => {
                localStorage.setItem('user_token', result.getUser().getToken().getSignedToken());
                this.redict_app();
            })
            .catch(error => {
                this.errorMessages.push('E-mail e/ou senha inválidos. Verifique se seu e-mail e senha estão corretos e tente novamente.');
            }).finally(() => {
                this.user.button = false;
            });
        },
        OnGoogleAuthFail (error) {
            this.errorMessages = [];
            this.errorMessages.push('E-mail e/ou senha inválidos. Verifique se seu e-mail e senha estão corretos e tente novamente.');
        }
    }
};

